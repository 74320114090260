import type { ParsedMenuData } from "astro-helpers/src/MenuOrganizer";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { IoChevronDown } from "react-icons/io5";
import { cn } from "astro-helpers/src/helpers/cn";

interface MobileNavbarProps {
  organizedMenu: ParsedMenuData;
}

export function MobileNavbar({
  organizedMenu,
}: MobileNavbarProps): JSX.Element {
  return (
    <div
      id="mobile-navbar"
      className="transition duration-500 absolute translate-y-[-200%] top-[var(--nav-height)] left-0 z-[150] bg-[var(--mobile-menu-color)] dark:bg-[var(--dark-mobile-menu-color)] w-full flex flex-col gap-4 justify-center items-center py-4"
    >
      <Accordion transition transitionTimeout={200}>
        {Object.keys(organizedMenu).map((key) => {
          if (organizedMenu[key].length === 1) {
            return (
              <div key={key}>
                <a
                  href={organizedMenu[key].at(0)?.link}
                  className="text-[var(--mobile-nav-links-color)] dark:text-[var(--dark-mobile-nav-links-color)]"
                >
                  {key}
                </a>
              </div>
            );
          }
          return (
            <AccordionItem
              key={organizedMenu[key].at(0)?.id}
              className="gap-2 text-nav-link"
              headingProps={{ className: "text-body" }}
              contentProps={{
                className: "transition-height duration-200 ease-out py-4",
              }}
              header={({ state }) => (
                <div className="flex items-center gap-2">
                  <span className="text-nav-links text-[var(--mobile-nav-links-color)] dark:text-[var(--darkmobile-nav-links-color)]">
                    {key}
                  </span>
                  <IoChevronDown
                    className={cn(
                      "text-nav-links transition duration-200",
                      state.isEnter ? "rotate-0" : "-rotate-90",
                    )}
                  />
                </div>
              )}
            >
              <ul>
                {organizedMenu[key].map((item) => (
                  <li key={item.id}>
                    <a
                      href={item.link}
                      className="text-[var(--mobile-nav-links-color)] dark:text-[var(--dark-mobile-nav-links-color)]"
                    >
                      {item.item}
                    </a>
                  </li>
                ))}
              </ul>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
}
